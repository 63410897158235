.cover-privacy{
    width:100%;
    height: 60vh;
    background-image: url("../../assats/bac.png");
    background-size: cover;
    background-position: center;
    margin-bottom: 5vh;
    display: flex
    ;
        flex-direction: column;
        align-items: center;
        justify-content: center;    
}
.title-privacy{
    font-size: 3rem;
    color: white;
}
@media (max-width:778px) {
    .cover-privacy{
        background-size: cover;
        background-position: right;
    }
    .title-privacy{
        font-size: 2rem;
    }
}
.content-privacy{
    width: 100%;
    padding: 50px 20px;
}
.titles-privacy{
    color: #0058a5;
    text-align: right;
    font-size: 1.7rem;

}
.descs-privacy{
    font-size: 18px;
    text-align: right;
}