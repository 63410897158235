video{
  width: 100%;
  height: 90vh;
  object-fit: cover;
}
.container-video-carousal{
  position: relative;
}
.filter-carousal-video{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1700008a;
  top: 0;
  right: 0;
  color: white;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.slideree{
  margin-bottom: 3rem;
}
.aaaaaaa{
  font-size: 4rem;
  font-weight: 900;
  color: white;
  text-shadow: 0px 0px 10px #ffffff7a;
}
.container-simple{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  padding-bottom: 1rem;
  animation:
  typing 3s steps(20);
 padding-top: 10px;
}
.name-company-color{
  color: #0756a5;
  text-shadow: 0px 0px 10px #ffffff3d;
}
@keyframes typing {
  from {width: 0;}
}
.container-simple2{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  height: 3rem;
  margin-bottom: 10%;
}
@keyframes typing2 {
  from {width: 0;}
}
.aaaaaaa2{
  font-size: 1.5rem;
  margin-top: 4rem;
  color: #e9e9e9;
  animation-name: example;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
@keyframes example {
  0%   {margin-top: 4rem;}
  
  100% {margin-top: 0px;}
}
@media (max-width:  767px) {
  .slideree{
    margin-bottom: 8rem;
  }
  .container-simple{
    animation:
    typing 1s steps(15),
    cursor .4s step-end infinite alternate ;
  }
  .aaaaaaa{
    font-size: 2rem;
  }
  video{
    height: 80vh;
    /* margin-bottom: 5rem; */
  }
}
.custom-edit-slider{
  width: 100%;
  height: 100vh;
}
.slide1{
  width: 100%;
  height: auto;
  background-color: #0756a5;
}
/* SwiperSlide {
  height: 100vh;
} */