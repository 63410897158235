.container-unis-project{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.card2-projects-page{
    margin-right: 2rem;
}
@media (max-width : 1200px) {
    .card2-projects-page{
        margin-right: 1.5rem;
    }
}