.payment-method-section{
    width: 100%;
    height:50vh;
    margin-bottom: 3rem;
}
.payment-method-container{
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.payment-method-left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;}
.payment-method{
    width: 90%;
    height: 30%;
    background-color: #EEEEEE;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 30px;
    border-radius: 10px;
    transition: 300ms ease;

}
.number-container{
    width: 50px;
    height: 50px;
    border: 1px solid #8B8B8B;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #8B8B8B;
    transition: 300ms ease;

}
.container-text-payment{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
    justify-content: center;
    transition: 300ms ease;

}
.heading-container-text-payment{
    font-size: 1.2rem;
    font-weight: 600;
    color: #8B8B8B;
    transition: 300ms ease;

}
.desc-container-text-payment{
    color: #8B8B8B;
    font-size: 0.8rem;
    transition: 300ms ease;

}
.payment-method:hover{
    background-color: #0756A5;
    transition: 300ms ease;
}
.payment-method:hover .number-container{
    color: white;
    border: 1px solid white;
    transition: 300ms ease;
}
.payment-method:hover .heading-container-text-payment{
    color: white;
    transition: 300ms ease;
}
.payment-method:hover .desc-container-text-payment{
    color: white;
    transition: 300ms ease;
}
@media (max-width: 767px) {
    .payment-method-section{
       height: auto;
    }
    .payment-method-container {
        height: auto;
        flex-direction: column;
    }
    .payment-method-left {
        width: 100%;
        height: 50vh;
    }
}