.section-6 {
    width: 100%;
    height: 80vh;
  }
  .content-section-6 {
    width: 100%;
    height: 50vh;
    background-color: #0756a5;
    margin-top: -160px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .title-section-6-end {
    color: #ffffff;
    font-size: 2.5rem;
    line-height: 55px;
    max-width: 70%;
    font-weight: bolder;
    line-height: 60px;
  }
  .desc-section-6 {
    color: #ffffff;
  }
  .btn2-section-6 {
    background-color: white;
    color: #0756a5;
  }
  @media (max-width: 992px) {
    .content-section-6{
      height: 45vh;
    }
    .sectionn-5 {
      height: auto;
      padding-bottom: 15rem;
  }
  .title-section-6-end {
    font-size: 2rem;
    max-width: 80%;
    line-height: 50px !important;
  
  }
  }
  @media (max-width: 767px) {
    .content-section-6 {
        height: 55vh;
        margin-top: -200px;
    }
  .title-section-6-end {
    line-height: 40px !important;
    font-size: 1.7rem;
}
  }