.container-slider-projects-home{
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
}
.ssssss{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    transition: 300ms ease;
}
.ssssss:hover{
    filter: brightness(100%);
    transition: 300ms ease;
}
.ttitle-project-card2{
    position: absolute;
    bottom: 100px;
    right: 30px;
}
.ccolor-text{
    position: absolute;
    bottom: 60px;
    right: 30px;
}
.wrapper-slide-item{
    width: calc(100% - 2rem);
    height: 70vh;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px #afafaf;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 2rem 1rem;
}
.swiper-button-prev,
.swiper-button-next{
    width: 30px !important;
    height:30px !important;
    background-color: #0756a5;
    color: white !important;
    border-radius: 50%;
    /* padding: 5px; */
  
}
.swiper-button-prev{
    margin-left: 5rem;
}
.swiper-button-next{
    margin-right: 5rem;
}
.swiper-button-prev::after,
.swiper-button-next::after{
    font-size: 1rem !important;
    font-weight: bolder !important;
}
@media (max-width: 1400px) {
    .swiper-button-prev{
        margin-left: 2rem;
    }
    .swiper-button-next{
        margin-right: 2rem;
    }
}
@media (max-width: 700px) {
    .slider-container{
        width: 80%;
      
    }
}
@media (max-width: 600px) {
    .swiper-button-prev{
        margin-left: 1rem;
    }
    .swiper-button-next{
        margin-right: 1rem;
    }
   
}