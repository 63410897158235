#add-project{
    height: 200vh;
    // overflow: scroll;
    .form-contactus {
        width: 55%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-end;
        padding: 10px 0px 10px 10px;
        .container-inputs {
            width: 100%;
            height: 12vh;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            .container-input {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .form-label {
                    font-size: 1rem;
                    color: #555555;
                    font-weight: 600;
                  }
                  .form-input-contactus {
                    width: 90%;
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #918e8e;
                    transition: 300ms ease;
                    direction: rtl;
                    font-weight: 600;
                  
                  }
              }
              .container-input-left {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .container-lable {
                    width: 90%;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                 .form-label {
                        font-size: 1rem;
                        color: #555555;
                        font-weight: 600;
                      }
                  }
                  .form-input-contactus {
                    width: 90%;
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #918e8e;
                    transition: 300ms ease;
                    direction: rtl;
                    font-weight: 600;
                  
                  }
              }
          }
          .container-input-full {
            // width: 100%;
            // display: flex;
            // flex-direction: column;
            // align-items: flex-end;
            .form-label {
                font-size: 1rem;
                color: #555555;
                font-weight: 600;
              }
.btn-img{
    color: #0756a5 !important;
    border-color: #0756a5 !important;
}
.btn-img:hover{
    color: white !important;
}
              .form-input-full-contactus {
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid #918e8e;
                transition: 300ms ease;
                direction: rtl;
                font-weight: 600;
              }
          }
          .form-check{
direction: rtl;
          }
          .btn-send-contactus {
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0);
            border-radius: 30px;
            background-color: #0756a5;
            color: white;
            padding: 5px;
            font-size: 1.2rem;
            font-weight: 600;
            transition: 300ms ease;
          
          }
          .btn-send-contactus:hover {
            transition: 300ms ease;
            background-color: white;
            color: #0756a5;
            border: 1px solid black;
          }
      }
}