.main-footer {
  height: 60vh;
  background-color: #0756a5;
}
.container-footer {
  height: 100%;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.top-footer {
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.container-menu-footer {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.container-menu-footer .menu-footer-item.active {
  color: white;
  font-size: 1.1rem;
  transition: 300ms ease;
}
.menu-footer-item {
  text-decoration: none;
  color: #dbdbdb;
  font-size: 1rem;
  /* width: 185px; */
  margin-bottom: 1rem;
}
.middle-footer {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: row-reverse;
}
.text-footer {
  color: white;
  text-align: right;
  max-width: 270px;
  direction: rtl;
}
.container-info {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}
.container-text-footer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.image-logo-footer {
  width: 150px;
}
.under-line-container {
  height: 8vh;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.conainer-socialmedia-icons {
  width: 50%;
  height: 5vh;
  background-color: aliceblue;
}
.link-phone-number {
  text-decoration: none;
  font-size: 1rem;
  color: #ffffffb2;
}
.address {
  font-size: 1rem;
  color: #ffffffb2;
  max-width: 90%;
  text-align: right;
}
.line {
  height: 1px;
  background-color: #0f72d6;
  margin-bottom: 5px;
}
.container-media-and-documentation {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.social-media-icons {
  width: 50%;
  height: 5vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.container-documentation {
  width: 50%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.documentation-text {
  font-size: 1rem;
  color: #ffffffb2;
}
.icon-social-media{
    color: #ffffff;
    font-size: 1rem;
    margin-left: 2rem;
}
@media (max-width: 1400px) {
  
  .container-menu-footer {
    width: 70%;
}
}
@media (max-width: 1200px) {
  
  .container-menu-footer {
    width: 80%;
}
}
@media (max-width: 992px) {
  .container-menu-footer {
    width: 70%;
    flex-wrap: wrap;
}
.text-footer {
  font-size: 0.9rem;
}
}
@media (max-width: 770px) {
  .main-footer {
    height: auto;
    padding-bottom: 2rem;
  }
  .top-footer{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .image-logo-footer {
    margin-top: 2rem;
    width: 250px;
    margin-bottom: 1rem;
}
.container-menu-footer{
  width: 100%;
}
}
@media (max-width: 667px) {
  .main-footer {
    height: auto;
    padding-bottom: 2rem;
  }
  .top-footer{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .image-logo-footer {
    margin-top: 2rem;
    width: 250px;
    margin-bottom: 1rem;
}
.container-menu-footer {
  flex-wrap: wrap;
}
.middle-footer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0rem;
}
.container-info {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.address {
  font-size: 0.8rem;
  color: #ffffffb2;
  max-width: 90%;
  text-align: center;
}
.text-footer {
  color: white;
  text-align: center;
  max-width: 90%;
  direction: rtl;
}
.container-info {
  width: 70%;
}
.container-text-footer {
  width: 100%;
}
}