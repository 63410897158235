.container-server-tabs-color{
  width: 100%;
  padding: 2rem 0px;
}
.section-3 {
    height: 90vh;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
  .left-section-3 {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding-top: 2rem;
  }
  .right-section-3 {
    width: 60%;
    height: 100%;
    display: none;
  }
  .show-right-section-3 {
    display: block;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    padding-top: 2rem;
  }
  .section-3-services-text {
    font-size: 1rem;
    color: #707070;
  }
  .head-text-section-2-3 {
    font-size: 2rem;
    max-width: 350px;
  }
  .container-tabs-section-3 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .tab {
    font-size: 1.2rem;
    font-weight: 300;
    color: #707070;
    margin-bottom: 1.5rem;
    cursor: pointer;
    transition: 300ms ease;
  }
  .active {
    font-size: 1.3rem;
    color: #0756a5;
    padding-right: 0.6rem;
    font-weight: 600;
    transition: 300ms ease;
  }
  .tab-image {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border-radius: 10px;
}
  
  .right-title-tabs {
    max-width: 100%;
  }
  .right-desc-tabs {
    max-width: 90%;
    font-size: 1rem;
  }
  .container-btn-section-3 {
    width: 100%;
    height: auto;
    padding: 1rem 0rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media (max-width: 992px) {
    .section-3 {
      height: 70vh;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;
    }
    .head-text-section-2-3 {
      font-size: 1.7rem;
      max-width: 95%;
    }
    .desc-section-t {
      max-width: 95%;
    }
    .active {
      font-size: 1rem;
      padding-right: 0.4rem;
      font-weight: 600;
    }
    .tab {
      font-size: 0.9rem;
      margin-bottom: 1.2rem;
    }
    .head-text-section-2-3 {
      font-size: 1.5rem;
      max-width: 95%;
    }
    .right-desc-tabs {
      max-width: 95%;
      font-size: 0.7rem;
    }
  }
  @media (max-width: 767px) {
    .container-server-tabs-color{
      padding: 1rem 0px;
    }
    .section-3 {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .left-section-3 {
      width: 100%;
    }
    .show-right-section-3 {
      width: 100%;
    }
    .head-text-section-2-3 {
      font-size: 2rem;
    }
    .container-tabs-section-3 {
    margin-bottom: 1rem;
    }
    .tab-image {
      margin-bottom: 1rem;
  }
    .desc-section-t {
      max-width: 95%;
      font-size: 1rem;
      line-height: 25px;
    }
    .tab {
      font-size: 1.1rem;
      margin-bottom: 1rem;
  }
    .active {
      font-size: 1.3rem;
      padding-right: 0.4rem;
      font-weight: 600;
    }
    .show-right-section-3{
      padding-top: 0px;
    }
    .container-btn-section-3 {
      flex-direction: row-reverse;
    }
  }