.card2-projects-page {
  position: relative;
  height: 70vh;
  width: 30%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #afafaf;
  border: 1px solid #0756a5;
  margin-bottom: 2rem;
}
.container-image-project{
  height: 40%;
  width: 100%;
background-position: center;
background-size: cover;
}
.project-image-card2 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.container-ditalse-projects-card {
  width: 100%;
  height: 50%;
  background-color: #0756a5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 10px;
}
.btn-project-card2 {
  width: 100%;
  height: 10%;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  font-weight: bolder;
  color: #0756a5;
  transition: 300ms ease;
}
.btn-project-card2:hover {
  background-color: #0756a5;
  color: white;
  transition: 300ms ease;
}
.container-project-name-card2 {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.price-project-card2 {
  width: 100%;
  height: 15%;
}
.space-project-card2 {
  width: 100%;
  height: 15%;
}
.roms-project-card2 {
  width: 100%;
  height: 15%;
}
.features-project-card2 {
  width: 100%;
  height: 15%;
}
.display-card2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.color-text{
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.price-no{
  font-size: 1.1rem;
}
.price-item{
  font-weight: 600;
  margin-right: 0.2rem;
}
.title-project-card2{
    font-size: 1.3rem;
    font-weight: bolder;
    color: white;
}
@media (max-width: 992px) {
  .card2-projects-page {
    width: 45%;
  }
}
@media (max-width: 767px) {
  .card2-projects-page {
    width: 100%;
    height: 80vh;
  }
}
.pag{
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}
.pagenation-container{
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid rgb(104, 104, 104);
  margin: 0px 5px  ;
  cursor: pointer;
  border-radius: 5px;
  transition: 300ms ease;
}
.activated{
  background-color: #0756a5;
  color: white;
  margin-bottom: 5px;
  transition: 300ms ease;
  border: none;
}