.section-4 {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
.section-4-upd {
    width: 100%;
    height: 50vh;
    margin: 3rem 0px;
  }
  .top-section-4 {
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .title-our-project-section-4 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 1rem;
  }
  .desc-our-project-section-4 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .title-section4 {
    max-width: 70%;
  }
  /* .middle-section-4 {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  } */
  .desc-section-4-text {
    text-align: left;
    max-width: 60%;
  }
  .bottom-section-4 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border: 1px solid #0756a5;
  }
  .left-bottom-section-4 {
    width: 50%;
    height: 100%;
    border: 1px solid #0756a5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right-bottom-section-4 {
    background-color: #0756a5;
    width: 50%;
    height: 100%;
    padding: 2rem 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
  }
  .desc-bottom-section-4 {
    direction: rtl;
    font-size:1rem;
    color: #ebebeb;
    /* max-width: 60%; */
    text-align: right;
    line-height: 30px;
  }
  .title-bottom-section-4 {
    font-weight: bolder;
    font-size: 2.5rem;
    color: white;
    /* max-width: 60%; */
    text-align: right;
    line-height: 50px;
  }
  .container-button-home-page{
    width: 100%;
  }
  .btn2-section-4:hover {
    background-color: white;
    color: #0756a5;
  }
  @media (max-width: 992px) {
    .section-4 {
       height: auto;
    }
    .middle-section-4 {
      height: 60vh;
  }
  .top-section-4 {
    height: 20vh;
  }
  .bottom-section-4 {
    height: 40vh;
  }
    .title-section4 {
      max-width: 85%;
    }
    .desc-section-4-text {
      max-width: 80%;
    }
    .middle-section-4 {
      height: 60vh;
    }
    .right-bottom-section-4 {
      padding: 1.5rem 5%;
    }
    .title-bottom-section-4 {
      font-size: 2rem;
      line-height: 40px;
    }
    .desc-bottom-section-4 {
      font-size: 0.8rem;
      line-height: 21px;
    }
  }
  @media (max-width: 767px) {
    .section-4 {
      height: auto;
      
    }
    .top-section-4 {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
  }
  .title-our-project-section-4 {
    width: 100%;
  }
.desc-our-project-section-4 {
  width: 100%;  
  align-items: flex-end;
  margin-bottom: 1rem;
  }
  .desc-section-4-text {
    text-align: right;
  }
    .middle-section-4 {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .desc-bottom-section-4 {
      font-size: 1rem;
    }
    .title-bottom-section-4 {
      font-size: 2rem;
      line-height: 30px;
      margin-bottom: 1rem;
    }
    .right-bottom-section-4 {
      padding: 1rem 2%;
    }
    .bottom-section-4 {
      display: flex;
      flex-direction: column;
      height: auto;
      /* margin-bottom: 10rem; */
    }
    .section-4-upd {
      height: auto;
    }
    .left-bottom-section-4 {
      width: 100%;
    }
    .right-bottom-section-4 {
      width: 100%;
      height: 30vh;
    }
    .container-button-home-page {
      width: 80%;
  }
  }