#login-page {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-dashboard {
    width: 40%;
    height: 50vh;
    background-color: rgba(202, 202, 202, 0.3882352941);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    border-radius: 10px;
    padding: 0rem 1rem;
    .form-input-full-contactus {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #918e8e;
      transition: 300ms ease;
      direction: rtl;
      font-weight: 600;
      margin-bottom: 2rem;
    }
    .btn-send-contactus {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0);
      border-radius: 30px;
      background-color: #0756a5;
      color: white;
      padding: 5px;
      font-size: 1.2rem;
      font-weight: 600;
      transition: 300ms ease;
    }
    .btn-send-contactus:hover {
      transition: 300ms ease;
      background-color: white;
      color: #0756a5;
      border: 1px solid black;
    }
  }
}
@media (max-width: 992px) {
  #login-page {
    .login-dashboard {
      width: 60%;
      height: 50vh;

      .form-input-full-contactus {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #918e8e;
        transition: 300ms ease;
        direction: rtl;
        font-weight: 600;
        margin-bottom: 2rem;
      }
    }
  }
}
@media (max-width: 767px) {
  #login-page {
    .login-dashboard {
      width: 100%;

      .form-input-full-contactus {
      }
    }
  }
}
