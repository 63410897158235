.sectionn-5 {
    width: 100%;
    height: 90vh;
    background-color: #f5f5f5;
    padding-top: 3rem;
  }
  .container-cintent-section-5 {
    height: 48vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
  .right-items-section-5 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    align-content: space-between;
  }
  .info-section-5 {
    width: 45%;
    background-color: rgb(89, 89, 89);
    height: 100%;
  }
  .item-container-section-5 {
    width: 46%;
    height: 46%;
    background-color: #eeeeee;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    padding: 0px 1rem;
  }
  .icon-item-section-5 {
    font-size: 2rem;
    color: #8b8b8b;
  }
  .title-item-section-5 {
    font-size: 1.1rem;
    font-weight: 700;
    color: #8b8b8b;
    text-align: right;
  }
  .desc-item-section-5 {
    color: #8b8b8b;
    font-size: 0.7rem;
    font-weight: 500;
    text-align: right;
  }
  .desc-section-6 {
    color: #ffffff;
    max-width: 80%;
}
  .title-item-section-5-s{
    color: #FFFFFF;
  }
  .item-container-section-5-s {
    background-color: #0756a5;
  }
  .icon-item-section-5-s,
  .title-item-section-5-s,
  .title-item-section-5-s {
    color: white;
  }
  @media (max-width: 992px) {
    .sectionn-5 {
      height: auto;
      padding-bottom: 2rem;
    }
    .icon-item-section-5 {
      font-size: 1.5rem;
    }
    .title-item-section-5 {
      font-size: 0.9rem;
    }
    .desc-item-section-5 {
  
      font-size: 0.6rem;
    }
    .right-items-section-5 {
      width: 60%;
    }
  }
  @media (max-width: 767px) {
    .container-cintent-section-5 {
        height: auto;
        display: flex;
        flex-direction: column;
  }
  .right-items-section-5 {
      width: 100%;
      height: 45vh;
  }

  }
  /* ----------- section 6 ---------- */
  
 