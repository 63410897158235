.main-section2-about {
  background-color: #f5f5f5;
}
.container-section2-about {
  height: 70vh;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 4rem 0px;
}
.item-column1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  border-radius: 10px;
  justify-content: space-between;
background-color: #EEEEEE;
}
.icon-item-column {
  font-size: 2rem;
  color: #3f4a5c;
}
.top-column1-about {
  width: 100%;
  height: 45%;
}
.bottom-column1-about {
  width: 100%;
  height: 45%;
}
.column-section2-about {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title-item-column{
    font-size: 1.5rem;
    font-weight: 600;
}
.desc-item-column{
    text-align: right;
    font-size: 0.9rem;
}
.s-item-color{
    background-color: #0756A5;
}
.s-color{
    color: white;
}
.column1 {
  width: 25%;
  height: 100%;

}
.column2 {
  width: 30%;
  height: 100%;
}
.top-column2-about{
    width: 100%;
    height: 60%;
 
}
.bottom-column2-about{
    width: 100%;
    height: 30%;
object-fit: cover;
border-radius: 10px;
background-position: center;
}
.column3 {
  width: 40%;
  height: 100%;
}
.top-column3-about{
    width: 100%;
    height: 45%;
    background-position: center;
    object-fit: cover;
    border-radius: 10px;
}
.bottom-column3-about{
    height: 45%;
}
@media (max-width: 992px) {
    .container-section2-about {
        height: 110vh;
        flex-wrap: wrap;
      }
      .column1{
        width: 43%;
        height: 75vh;
      }
      .column2{
        width: 53%;
        height: 75vh;
      }
      .column3{
        width: 100%;
        height: 25%;
      }
      .column-section2-about-tab {
        flex-direction: row;
        margin-top: 1vh;
      }
      .bottom-column3-about {
        height: 100%;
        width: 48%;
    }
    .top-column3-about{
        width: 48%;
        height: 100%;
    }
}
@media (max-width: 992px) {
    .container-section2-about {
        height: auto;
        flex-wrap: wrap;
      }
      .column1{
        width: 100%;
        height: 60vh;
      }
      .column2{
        width: 100%;
        height: 60vh;
        margin-top: 1rem;
      }
      .top-column2-about {
        height: 50%;
    }
    .bottom-column2-about {
        height: 40%;
    }
      .column3{
        width: 100%;
        height: 25%;
      }
      .column-section2-about-tab {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 1vh;
      }
      .top-column3-about{
        width: 100%;

      }
      .bottom-column3-about {
        width: 100%;
        margin-bottom: 1rem;
    }
}