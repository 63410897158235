.form-contactus {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 0px 10px 10px;
  }
  .contant-contactus {
    width: 38%;
    height: 100%;
    padding: 10px 10px 10px 0px;
  }
  .container-inputs {
    width: 100%;
    height: 12vh;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .container-input {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .container-input-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .container-input-full {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .form-label {
    font-size: 1rem;
    color: #555555;
    font-weight: 600;
  }
  .form-input-contactus {
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #918e8e;
    transition: 300ms ease;
    direction: rtl;
    font-weight: 600;
  
  }
  .form-input-full-contactus {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #918e8e;
    transition: 300ms ease;
    direction: rtl;
    font-weight: 600;
   
  }
  .form-input-full-contactus-message {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #918e8e;
    direction: rtl;
    font-weight: 600;
    transition: 300ms ease;
    margin-bottom: 1rem;
  }
  .container-lable {
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .form-input-full-contactus-message:focus {
    outline: none;
    border: none;
    box-shadow: 0px 0px 10px 0px #0756a566;
    padding: 13px;
    transition: 300ms ease;
  
  }
  .form-input-contactus:focus {
    outline: none;
    border: none;
    box-shadow: 0px 0px 10px 0px #0756a566;
    padding: 13px;
    transition: 300ms ease;
  
  }
  .form-input-full-contactus:focus {
    outline: none;
    border: none;
    box-shadow: 0px 0px 10px 0px #0756a566;
    padding: 13px;
    transition: 300ms ease;
    
    font-weight: 600;
  }
  .top-content-contactus-page {
    width: 100%;
    height: 50%;
  }
  .bottom-content-contactus-page {
    width: 100%;
    height: 50%;
    border-radius: 10px;
    overflow: hidden;
  }
  .location-container {
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .icon-location {
    font-size: 1rem;
    color: #555555;
  }
  .icon-locationn {
    font-size: 1rem;
    color: #555555;
    margin-left: 2rem;
  }
  .location-address {
    font-size: 0.8rem;
    max-width: 300px;
    text-align: right;
    line-height: 20px;
    margin-right: 1rem;
  }
  .btn-send-contactus {
    width: 100%;
    border: none;
    border-radius: 30px;
    background-color: #0756a5;
    color: white;
    padding: 5px;
    font-size: 1.2rem;
    font-weight: 600;
    transition: 300ms ease;
  
  }
  .btn-send-contactus:hover {
    transition: 300ms ease;
    background-color: white;
    color: #0756a5;
    border: 1px solid black;
  }
  @media (max-width: 767px) {
      .form-contactus {
        width: 100%;
        height: 100vh; 
      }
      .contant-contactus {
        width: 100%;
        height: 100vh;
    }
}