.project {
  position: relative;
  width: 32%;
  height: 90%;
  background-color: #0756a5;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.card2-unit-page {
  /* position: relative; */
  height: 70vh;
  width: 30%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #afafaf;
  border: 1px solid #0756a5;
  margin-bottom: 2rem;
}
.unit-image-card2 {
  height: 40%;
  width: 100%;
  object-fit: cover;
}
.image-progect {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  top: 0;
  left: 0;
  filter: brightness(40%);
  transition: 300ms ease;
}
.title-unit-card2{
  font-size: 1.3rem;
  font-weight: bolder;
  color: white;
}
.title-project {
  position: absolute;
  bottom: 70px;
  right: 15px;
  color: white;
  font-size: 1.3rem;
  font-weight: 700;
}
.desc-project {
  position: absolute;
  bottom: 0px;
  right: 15px;
  text-align: right;
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 300;
  max-width: 60%;
}
.icon-project {
  position: absolute;
  bottom: 20px;
  left: 15px;
  color: white;
  font-size: 1.5rem;
  display: none;
}
.project:hover .image-progect {
  filter: brightness(60%);
  transition: 300ms ease;
}
.project:hover .icon-project {
  display: block;
  transition: 300ms ease;
}
@media (max-width: 992px) {
  .title-project {
    font-size: 1.1rem;
  }
  .desc-project {
    font-size: 0.6rem;
    max-width: 70%;
  }
}
@media (max-width: 767px) {
  .project {
    width: 100%;
    height: 70vh;
    margin-bottom: 1rem;
  }
  .title-project {
    font-size: 1.5rem;
  }
  .desc-project {
    font-size: 1rem;
  }
}
