.mainnnn{
    width: 100%;
    height: 105vh;
    background-color: #F5F5F5;
    margin-bottom: 3rem;
    padding: 5vh 0px;
}
.container-section2-sale-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.bac-top-section2 {
  width: 100%;
  height: 40vh;
  background-position: center;
  object-fit: cover;
  border-radius: 10px;
}
.container2-section2-sale-page{
    width: 100%;
    display: flex;
    height: 25vh;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.heading-container2-section2-sale-page {
  max-width: 380px;
}
.bottom-container2-section2-sale-page{
    width: 100%;
    height: 23vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.item-container2{
    width: 23%;
height: 100%;
}
.container-button-sale-page{
    width: 50%;
}
.ali{
  font-size: 1rem;
}
@media (max-width: 767px) {
  .mainnnn{
    height: auto;
}
.container-section2-sale-page {
  height: auto;
}
.container2-section2-sale-page {
  width: 100%;
  display: flex;
  padding-top: 1rem;
  height: 38vh;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.bottom-container2-section2-sale-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.item-container2 {
  width:80%;
  height: 33vh;
  margin-bottom: 1rem;
}
.container-button-sale-page {
  width: 70%;
}
}